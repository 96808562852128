import { Accordion } from "@zendeskgarden/react-accordions";
import { Col, Grid, Row } from "@zendeskgarden/react-grid";
import { Dropdown, Field as DropField, Item, Menu, Select } from "@zendeskgarden/react-dropdowns";
import { Label as LabelForm, Input } from "@zendeskgarden/react-forms";
import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Loading from "../../../components/loading";
import MosaicPreview from "../../../components/mosaic/preview";
import ReactComponents from "../../../images/enter-loading.png";
import { LG } from '@zendeskgarden/react-typography';
import { ReactComponent as IconCv } from "../../../images/icons/ic_attach_file_24px.svg";
import { ReactComponent as IconPhone } from "../../../images/icons/ic_phone_24px.svg";
import { ReactComponent as IconCarrer } from "../../../images/icons/ic_gps_fixed_24px.svg";
import { ReactComponent as IconAddInformation } from "../../../images/icons/ic_content_paste_24px.svg";
import { ReactComponent as IconBehavior } from "../../../images/icons/ic_done_all_24px.svg";
import { ReactComponent as IconGraduation } from "../../../images/icons/ic_school_24px.svg";
import { ToggleIconButton } from '@zendeskgarden/react-buttons';
// @ts-ignore
import FilestackReact from "filestack-react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import {
  OpportunityData,
  UserData,
  UserOpportunities,
  avaliacaoUsuario,
} from "../../../model/user.model";
import UserService from "../../../services/user.service";
import DocumentsService from "../../../services/documents.service";
import FileStackService from "../../../services/filestack.service";
import { AuthenticationService } from "../../../services/auth.service";
import api from "../../../services/api.service";
import { AlertService } from '../../../services/alert.service';
import TranslationService, { t } from "../../../translations/translation.service";
import "./index.css";
import {
  Field,
  Label,
} from "@zendeskgarden/react-forms";
import { Tooltip } from "@zendeskgarden/react-tooltips";
import { ThemeProvider } from '@zendeskgarden/react-theming';
import { theme } from "../../../util/colorButton";
import service from "../../../services/api.service";
import ProfessionalFeedCard from "../../../components/professional-journey/professional-feed";
import { ReactComponent as StarFillIcon } from '@zendeskgarden/svg-icons/src/16/star-fill.svg';
import { ReactComponent as StarStrokeIcon } from '@zendeskgarden/svg-icons/src/16/star-stroke.svg';
import { ReactComponent as GlobeFillIcon } from '@zendeskgarden/svg-icons/src/16/globe-fill.svg';
import { ReactComponent as GlobeStrokeIcon } from '@zendeskgarden/svg-icons/src/16/globe-stroke.svg';
import { ReactComponent as HomeFillIcon } from '@zendeskgarden/svg-icons/src/16/home-fill.svg';
import { ReactComponent as HomeStrokeIcon } from '@zendeskgarden/svg-icons/src/16/home-stroke.svg';
import { ReactComponent as ChevronDoubleDownStrokeIcon } from '@zendeskgarden/svg-icons/src/12/chevron-double-down-stroke.svg';
import { ReactComponent as ChevronDoubleUpStrokeIcon } from '@zendeskgarden/svg-icons/src/12/chevron-double-up-stroke.svg';
import Moment from "moment";
import { coinType, priceRange } from '../../../util/opportunity';
import TechnicalAdvicePanel from "./components/technical-advice";
import avatar from "../../../images/avatar.jpg";
import OpportunityService from "../../../services/opportunity.service";
import { FooterItem } from "@zendeskgarden/react-modals";
import { ReactComponent as InfoIcon } from "../../../images/icons/info.svg";

const dateFormat = "DD/MM/YYYY";

class ProfessionalView extends React.Component<any> {
  state = {
    avaliacoesUsuario: [] as avaliacaoUsuario[],
    user: {} as UserData,
    opportunitiesRelated: {
      inProgressOpportunities: [] as OpportunityData[],
      finishedOpportunities: [] as OpportunityData[],
    } as UserOpportunities,
    loading: true,
    loadingFeed: true,
    width: 0,
    height: 0,
    attachments: [] as any[],
    comments: "",
    showModal: false,
    showModalRemoveCv: false,
    cvFile: [] as any[],
    disabledButton: false,
    expandedSections: [] as any,
    isTalent: false,
    isEluminer: false,
    deleteId: "",
    showPretensao: false,
    showUrlLinkedIn: false,
    contractTypeArray: [] as string[],
    policyAndSignature: [] as string[],
    policyString: '' as string,
    signatureString: '' as string,
    policyAndSignatureImg: '' as string,
    europeanUnionCitizenship: false,
    showFeed: false,
    modal: false,
    edit: false,
    email: "",
    eventID: "",
    inscribeModal: false,
    opportunities: [] as any[],
    selectedId: "",
    currentLang: TranslationService.getCurrentLanguage,
    processType: "",
  };

  constructor(props: any) {
    super(props);
    this.footerRef = React.createRef();
    this.updateWidth = this.updateWidth.bind(this);
    this.deleteCv = this.deleteCv.bind(this);
    this.getOpportunityStep = this.getOpportunityStep.bind(this);
    this.getFileStackPolicy = this.getFileStackPolicy.bind(this);
    this.setUrlAttachment = this.setUrlAttachment.bind(this);
    this.policeAndSignatureString = this.policeAndSignatureString.bind(this);
    this.setUrlImg = this.setUrlImg.bind(this);
    this.getEventID = this.getEventID.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  footerRef!: any;

  handleChange(event: any | string) {
    const { target } = event;
    const { name } = target;
    const userInfo = this.state.user;
    const findKey = Object.keys(userInfo).find((key) => key === name);
    if (!findKey) {
      this.setState({
        user: userInfo,
      });
    } else {
      userInfo.linkedinUrl = target.value;
      this.setState({
        user: userInfo,
      });
    }
  }

  updateWidth() {
    const width = this.footerRef.current.parentNode.clientWidth;
    const height = this.footerRef.current.parentNode.clientHeight;
    this.setState({ width, height });
  }

  reloadPage(): void {
    window.location.reload();
  }

  async getFileStackPolicyToUserImg(): Promise<void> {
    try {
      if (
        this.state.user &&
        this.state.user.userImageUrl &&
        this.getNewFileStackUrl(this.state.user.userImageUrl)
      ) {
        const policyUserImg = await FileStackService.getFileStackSecreteCode(
          this.state.user.userImageUrl, false
        );
        this.setState({ policyAndSignatureImg: policyUserImg.data });
      }
    } catch (err: any) {
      console.log("Erro ao obter credenciais - ERROR: ", err);
    }
  }

  getEventID(value: string) {
    this.setState({
      eventID: value,
    })
  }

  getNewFileStackUrl(url: string): boolean {
    const domain = url.split('/')[2];
    if (domain.includes('cdn.file')) {
      return true;
    }
    return false;
  }

  setUrlImg(url: string): string {
    const { policyAndSignatureImg } = this.state;
    const splitedUrl = url.split('/');
    const newUrl = `https://${splitedUrl[2]}${policyAndSignatureImg}/${splitedUrl[splitedUrl.length - 1]}`
    return newUrl;
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.policeAndSignatureString();
    if (id) {
      this.setState({ message: undefined });
      UserService.getUserWithOpportunities(id)
        .then((resp) => {
          if (
            resp.data.opportunitiesRelated.inProgressOpportunities.length >
            0
          ) {
            resp.data.opportunitiesRelated.inProgressOpportunities.map(
              (item: any) => (item.fase = this.getOpportunityStep(item))
            );
          }
          this.setState(
            {
              user: resp.data.user,
              loading: false,
              opportunitiesRelated: resp.data.opportunitiesRelated,
              avaliacoesUsuario: resp.data.user.avaliacoesUsuario,
              attachments: resp.data.user.attachments,
              isTalent: resp.data.user.talent.status,
              isEluminer: resp.data.user.eluminer.status,
              europeanUnionCitizenship: resp.data.user.europeanUnionCitizenship
            },
            () => {
              this.getFileStackPolicy();
              this.getFileStackPolicyToUserImg();

              const phoneIndex = this.state.user.notificationsSettings.channels.findIndex(
                (channel: any) => channel.name === "Telefone"
              );
              const emailIndex = this.state.user.notificationsSettings.channels.findIndex(
                (channel: any) => channel.name === "E-mail"
              );
              const hasPhoneContactPermission = this.state.user.notificationsSettings.isActive
                && this.state.user?.notificationsSettings.channels[phoneIndex]?.isActive;
              const hasMailContactPermission = this.state.user.notificationsSettings.isActive
                && this.state.user?.notificationsSettings.channels[emailIndex]?.isActive;
              if (this.state.user &&
                this.state.user.email
              ) {
                let email = hasMailContactPermission
                  ? this.state.email
                  : this.formatEmail(this.state.email)
                let user = { ...this.state.user, email }
                this.setState({ user: user })
              } else {
                let email = "";
                this.setState({ user: { ...this.state.user, email } })
              }

              if (this.state.user &&
                this.state.user.contato &&
                this.state.user.contato.lista[0] &&
                this.state.user.contato.lista[0].contato
              ) {
                const contato = this.state.user.contato;
                contato.lista[0].contato = this.formatContact(this.state.user.contato.lista[0].contato, hasPhoneContactPermission);
                this.setState({ user: { ...this.state.user, contato } });
              } else {
                const contato = {
                  lista: [{
                    locale: "pt-BR",
                    contato: "",
                    tipo: "Preferencial"
                  }]
                };
                this.setState({ user: { ...this.state.user, contato } });
              }
              if (!this.state.user.perfilUsuario.pretensao) {
                const perfilUsuario = this.state.user.perfilUsuario;
                perfilUsuario.pretensao = {
                  tipo: {
                    pj: {
                      moeda: "-", valor: "-"
                    },
                    clt: {
                      moeda: "-", valor: "-"
                    },
                    others: {
                      moeda: "-", valor: "-"
                    }
                  }
                }
                this.setState({
                  user: { ...this.state.user, perfilUsuario }
                });
              } else if (this.state.user.perfilUsuario.pretensao && !this.state.user.perfilUsuario.pretensao.tipo) {
                const perfilUsuario = this.state.user.perfilUsuario;
                perfilUsuario.pretensao = {
                  tipo: {
                    pj: {
                      moeda: "-", valor: "-"
                    },
                    clt: {
                      moeda: resp.data.user.perfilUsuario.pretensao.moeda,
                      valor: resp.data.user.perfilUsuario.pretensao.valor
                    },
                    others: {
                      moeda: "-", valor: "-"
                    }
                  }
                }
                this.setState({
                  user: { ...this.state.user, perfilUsuario }
                }, () => {
                  this.setState({ clt: this.state.user.perfilUsuario.pretensao.tipo.clt })
                });
              } else {
                if (
                  this.state.user.perfilUsuario.pretensao.tipo.pj
                ) {
                  this.setState(
                    {
                      pj: this.state.user.perfilUsuario.pretensao.tipo.pj
                    }
                  );
                }
                if (
                  this.state.user.perfilUsuario.pretensao.tipo.clt
                ) {
                  this.setState(
                    {
                      clt: this.state.user.perfilUsuario.pretensao.tipo.clt
                    }
                  );
                }
                if (
                  this.state.user.perfilUsuario.pretensao.tipo.others
                ) {
                  this.setState(
                    {
                      others: this.state.user.perfilUsuario.pretensao.tipo.others
                    }
                  );
                }
              }
              if (!this.state.user.recruiterInputs.pretensao) {
                const recruiterInputs = this.state.user.recruiterInputs;
                recruiterInputs.pretensao = {
                  tipo: {
                    pj: {
                      moeda: "-", valor: "-"
                    },
                    clt: {
                      moeda: "-", valor: "-"
                    },
                    others: {
                      moeda: "-", valor: "-"
                    }
                  }
                }
                this.setState({
                  user: { ...this.state.user, recruiterInputs }
                });
              } else if (this.state.user.recruiterInputs.pretensao && !this.state.user.recruiterInputs.pretensao.tipo) {
                const recruiterInputs = this.state.user.recruiterInputs;
                recruiterInputs.pretensao = {
                  tipo: {
                    pj: {
                      moeda: "-", valor: "-"
                    },
                    clt: {
                      moeda: resp.data.user.recruiterInputs.pretensao.moeda,
                      valor: resp.data.user.recruiterInputs.pretensao.valor
                    },
                    others: {
                      moeda: "-", valor: "-"
                    }
                  }
                }
                this.setState({
                  user: { ...this.state.user, recruiterInputs }
                }, () => {
                  this.setState({ clt: this.state.user.recruiterInputs.pretensao.tipo.clt })
                });
              } else {
                if (
                  this.state.user.recruiterInputs.pretensao.tipo.pj
                ) {
                  this.setState(
                    {
                      pj: this.state.user.recruiterInputs.pretensao.tipo.pj
                    }
                  );
                }
                if (
                  this.state.user.recruiterInputs.pretensao.tipo.clt
                ) {
                  this.setState(
                    {
                      clt: this.state.user.recruiterInputs.pretensao.tipo.clt
                    }
                  );
                }
                if (
                  this.state.user.recruiterInputs.pretensao.tipo.others
                ) {
                  this.setState(
                    {
                      others: this.state.user.recruiterInputs.pretensao.tipo.others
                    }
                  );
                }
              }
              this.updateWidth();
              window.addEventListener("resize", this.updateWidth);
              this.setState({
                contractTypeArray: this.getContractType(this.state.user.hiringModel)
              });
            }
          );
        })
        .catch((error) => {
          console.error(error);
          this.setState({ message: "Usuário não encontrado" });
          this.setState({ loading: false });
        });
    } else {
      this.setState({ loading: false, message: "Usuário não encontrado" });
    }
  }

  async updateUserTalentStatus() {
    const userId = this.props.match.params.id;
    service.put("/profile/turn-talent/" + userId);
  }

  async updateEluminerStatus(status: boolean) {
    const userId = this.props.match.params.id;
    try {
      await UserService.updateProfessionalEluminerStatus(userId, status);
      AlertService.success(t("Profissional atualizado!"))
    } catch (err) {
      AlertService.error(t("Erro ao atualizar profissional"));
      console.log("Erro: ", err);
    }
  }

  async updateEuropeanUnionCitizenshipStatus(status: boolean) {
    const userId = this.props.match.params.id;
    try {
      await UserService.updateEuropeanUnionCitizenship(userId, status);
      AlertService.success(t("Profissional atualizado!"))
    } catch (err: any) {
      AlertService.error(t("Erro ao atualizar profissional"));
      console.log("Erro: ", err);
    }
  }

  async updateSalaryExpectation(user: UserData): Promise<void> {
    const validCompensationExpectation = this.isInValidFields(this);
    if (validCompensationExpectation) {
      AlertService.error(t("Pretensão salarial obrigatória. Os campos moeda e faixa devem ser preenchidos"));
      return;
    }
    try {
      await UserService.updateUser(user);
      AlertService.success("Pretensão salarial atualizada");
    } catch (error: any) {
      AlertService.error("Não foi possível atualizar a pretensão salarial");
      console.log(error.response.data.message)
    }
  }

  async updateRecruiterInputs(user: UserData): Promise<void> {
    try {
      console.log("user", user);
      user.recruiterInputs.lastUpdated = new Date();
      await UserService.updateUser(user);
      AlertService.success("Informações do usuário atualizadas com sucesso.");
    } catch (error: any) {
      AlertService.error("Não foi possível atualizar as informações do usuário.");
      console.log(error.response.data.message)
    }
  }

  isInValidFields = (self: any): boolean => {
    let isInvalid = false;
    self.state.contractTypeArray.forEach((tipo: string) => {
      if (self.state.user.perfilUsuario?.pretensao?.tipo[tipo]) {
        if (
          ((self.state.user.perfilUsuario.pretensao.tipo[tipo].moeda.includes('BRL' || 'USD' || 'EUR'))
            &&
            (self.state.user.perfilUsuario.pretensao.tipo[tipo].valor.includes('-')))
          ||
          ((self.state.user.perfilUsuario.pretensao.tipo[tipo].moeda.includes('-'))
            &&
            (self.state.user.perfilUsuario.pretensao.tipo[tipo].valor.trim() !== '-'))
        ) {
          isInvalid = true;
          return isInvalid;
        }
      }
    });
    return isInvalid;
  };

  getContractType(contract: any): string[] {
    const contractType: string[] = [];
    for (const index in contract) {
      if (contract[index]) contractType.push(index);
    }
    return contractType
  }

  getOpportunityStep(opportunity: any) {
    if (opportunity.quantidadeVagas === 0) {
      return "Encerrada";
    } else {
      if (!opportunity.isPublic) return "Revisão";
      switch (opportunity.fase) {
        case 0:
          return "Recrutamento";
        case 1:
          return "Recrutamento";
        case 2:
          return "Avaliação";
        case 3:
          return "Seleção";
        case 4:
          return "Apresentação";
        default:
          break;
      }
    }
  }

  getCandidateStatus(item: any) {
    const isDispensed = item.currentStatus === 3;
    const hasRefused = item.currentStatus === 4;
    const hasQuit = item.currentStatus === 5;
    const isSelected = item.currentStatus === 6;

    switch (
    isSelected
      ? 4
      : isDispensed
        ? 5
        : hasQuit
          ? 6
          : hasRefused
            ? 7
            : item.candidateStep === 0
              ? item.candidateStep
              : item.candidateStep - 1
    ) {
      case 0:
        return t("Recrutado");
      case 1:
        return t("Avaliado");
      case 2:
        return t("Curriculo Selecionado");
      case 3:
        return t("Apresentado");
      case 4:
        return t("Selecionado");
      case 5:
        return t("Dispensado");
      case 6:
        return t("Desistiu");
      case 7:
        return t("Recusado");
      default:
        break;
    }
  }

  getStatusOldOpp(item: any) {
    const status = item.candidateStep === 0 ? item.candidateStep : item.candidateStep - 1
    switch (status) {
      case 0:
        return t("Recrutamento");
      case 1:
        return t("Avaliação");
      case 2:
        return t("Seleção de Currículo");
      case 3:
        return t("Apresentação");
      default:
        break;
    }
  }

  changeCssStyle(text: string): string | null {
    if (!text) return null;
    const changeStyle = text
      .replaceAll("background-color:", "#")
      .replaceAll("color", "#");
    return changeStyle;
  }

  async getFile({ filesUploaded }: any) {
    let array = filesUploaded.map(function (item: any) {
      return {
        fileName: item.filename,
        url: item.url,
      };
    });
    const policyAndSignature = (await FileStackService.getFileStackSecreteCodeToUser(
      array[0].url, false
    )).data
    this.setState({
      cvFile: array[0],
      showModal: true,
      user: {
        ...this.state.user,
        policyAndSignature
      }
    });
  }

  async getFileStackPolicy(): Promise<void> {
    try {
      const policy = await FileStackService.getFileStackSecreteCode(this.state.attachments, true);
      this.setState({ policyAndSignature: policy.data });
    } catch (err: any) {
      console.log("Erro ao obter credenciais - ERROR: ", err);
    }
  }

  async policeAndSignatureString(): Promise<void> {
    try {
      const policyAndSignature = await FileStackService.getPoliceAndSignature();
      this.setState({
        policyString: policyAndSignature.data.policy,
        signatureString: policyAndSignature.data.signature
      });
    } catch (err: any) {
      console.log("ERROR: ", err);
    }
  }

  setUrlAttachment(url: string, index: number): string {
    const { policyAndSignature } = this.state;
    const splitedUrl = url.split('/');
    const newUrl = `https://${splitedUrl[2]}${policyAndSignature[index]}/${splitedUrl[splitedUrl.length - 1]}`
    return newUrl;
  }

  formatEmail(email: string) {
    const splitEmail = email.split("@");
    const domain = splitEmail[1];
    const name = splitEmail[0];
    return name.substring(0, 2).concat("*********@").concat(domain);
  }

  formatContact(contact: string, hasPhoneContactPermission: boolean) {
    const con = contact.replace("(", "").replace(")", "").replace("-", "");
    const formated = `(${con.substring(0, 2)}) ${hasPhoneContactPermission
      ? con.substring(2, con.length - 4)
      : Array(con.length - 4).join("*")
      }-${con.substring(con.length - 4)}`;

    return formated;
  }

  async uploadCvFile() {
    this.setState({ disabledButton: true });
    const data = {
      _id: this.state.user._id,
      file: this.state.cvFile,
      comment: this.state.comments,
      policyAndSignature: this.state.user.policyAndSignature
    };
    try {
      const resp = await api.post("documents/file/uploadCv", data);
      if (resp.status === 200) {
        this.setState({
          comments: "",
          showModal: false,
          attachments: resp.data,
          disabledButton: false,
        }, () => setTimeout(() => {
          this.reloadPage()
        }, 1000))
      }
    } catch (err) {
      console.error(err);
      this.setState({
        showModal: false,
        comments: "",
        disabledButton: false,
      });
    }
  }

  async deleteCv(attachment: any) {
    this.setState({ disabledButton: true });
    const policyAndSignature = (await FileStackService.getFileStackSecreteCode(
      attachment.url, false
    )).data
    const data = {
      id: this.state.user._id,
      cvId: attachment._id,
      policyAndSignature
    };
    try {
      const resp = await DocumentsService.deleteDocument(data);
      if (resp.status === 200) {
        this.setState({
          disabledButton: false,
          attachments: resp.data,
          showModalRemoveCv: false,
          deleteId: "",
        }, () => {
          AlertService.success(t("Arquivo excluido com sucesso."))
        });
      }
    } catch (err) {
      console.error(err);
      this.setState({
        disabledButton: false,
        showModalRemoveCv: false,
        deleteId: "",
      }, () => {
        AlertService.error(t("Infelizmente não foi possível excluir o arquivo agora. Por favor tente de novo mais tarde."))
      });
    }
  }

  getItemCaption = (e: any): string => {
    switch (parseInt(e)) {
      case 1:
        return t("Básico");
      case 2:
        return t("Intermediário");
      case 3:
        return t("Avançado");
      default:
        return t("Básico");
    }
  };

  getOpportunities(processType: string) {
    console.log(this.state.user);
    this.setState({ loading: true, processType }, async () => {
      let opps = (await OpportunityService.getActiveOpportunities()).data;
      this.setState({
        inscribeModal: true,
        opportunities: opps,
        loading: false,
      });
    });
  }

  async becomeCandidateByRh() {
    this.setState({ loading: true }, async () => {
      try {
        let triageRecruitmentOptions;

        triageRecruitmentOptions = {
          details: "",
          date: new Date(Date.now()),
          capture: "",
          source: "",
        };

        const obj = {
          activeLanguage: this.state.currentLang(),
          _usuario: { _id: this.state.user._id },
          recruiter: AuthenticationService.getUser(),
          triageRecruitmentOptions: triageRecruitmentOptions,
          conversionType: this.state.processType,
          byRH: true,
        };
        const resp = await OpportunityService.becomeCandidateByRh(
          this.state.selectedId,
          obj
        );
        this.setState({ inscribeModal: false, loading: false }, () => {
          resp.status === 200
          ? AlertService.warn(t(resp.data.message))
          : AlertService.success(t(resp.data.message));
        });
      } catch (error) {
        console.error(error);
        AlertService.error(error);
        this.setState({ loading: false });
      }
    });
  }

  async saveEventContent(payload: any) {
    try {
      const response = await UserService.addEventToFeed(this.state.user._id, payload);
      AlertService.success(response.data.message);
    } catch (error: any) {
      AlertService.error("erro ao registrar evento");
      console.log("Erro ", error);
    }
  }

  async editEventContent(payload: any) {
    try {
      await UserService.editEventByRh(
        this.state.user._id,
        this.state.eventID,
        payload
      );
      AlertService.success(t("Evento atualizado com sucesso"));
      this.setState({ edit: false });
    } catch (error) {
      console.log("ERR0 na edição: ", error);
      AlertService.error(t("Erro ao atualizar evento"));
    }
  }

  componentDidUpdate(
    prevProps: any,
    _prevState: any
  ): void {
    if (prevProps.saveEvent !== this.props.saveEvent) {
      this.saveEventContent(this.props.saveEvent);
    }
    if (prevProps.editEvent !== this.props.editEvent) {
      this.editEventContent(this.props.editEvent);
    }
  }

  searchOpportunities = async (search: string) => {
    let opps = this.state.opportunities;
    this.setState({
      opportunities: [...opps.filter((opp: any) =>
        opp.titulo.toLowerCase().includes(search.toLowerCase())
      )]
    });
  }

  resetOpportunities = async () => {
    this.setState({ loading: true });
    let opps = (await OpportunityService.getActiveOpportunities()).data;
    this.setState({
      opportunities: opps,
      loading: false
    });
  }

  render() {
    if (this.state.loading) return <Loading show={this.state.loading} />;

    const emailIndex = this.state.user.notificationsSettings.channels.findIndex((channel: any) => channel.name === "E-mail");
    const phoneIndex = this.state.user.notificationsSettings.channels.findIndex((channel: any) => channel.name === "Telefone");

    const hasPhoneContactPermission =
      this.state.user.notificationsSettings.isActive &&
      this.state.user?.notificationsSettings.channels[phoneIndex]?.isActive;
    const hasMailContactPermission =
      this.state.user.notificationsSettings.isActive &&
      this.state.user?.notificationsSettings.channels[emailIndex]?.isActive;
    
    return (
      <div>
        <div className="container mt-4">
          <div>
            <Row>
              <Col size={12}>
                <p className="u-fs-lg u-semibold size-line">
                  {t("Currículo e informações profissionais")}
                </p>
              </Col>
              <Col
                size={12}
                style={{ borderBottom: "solid 1px #354A5F", opacity: 0.2 }}
                className="mb-4 mt-1"
              />
            </Row>
            <Row>
              <Col className="body-left" sm={12} lg={3}>
                <div
                  ref={this.footerRef}
                >
                  {this.state.user && this.state.user.perfilUsuario && (
                    <MosaicPreview
                      scale={this.state.width / 550}
                      mosaic={this.state.user.perfilUsuario.mosaico.escolhidas}
                    ></MosaicPreview>
                  )}
                </div>
                <div></div>

                <div className="inprogress-opportunities mt-4 mb-4 pt-4">
                  <div>
                    <Button
                      className="button-orange"
                      onClick={() => this.getOpportunities("candidate")}
                    >
                      {t("Tornar candidato")}
                    </Button>
                  </div>
                  {
                    // MODAL DE INSCRIÇÃO
                    this.state.inscribeModal && (
                      <Modal
                        scrollable
                        show={this.state.inscribeModal}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        onHide={() => this.setState({ inscribeModal: false })}
                      >
                        <Modal.Header>
                          <Row>
                            <Col size={12} className="mb-2">
                              {t("Em que oportunidade você quer inscrever @ profissional?")}
                              <button onClick={() => this.setState({ inscribeModal: false })} type="button" className="close" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </Col>
                            <Col size={12}>
                              <h4 style={{ color: '#ed961c' }}>{t("Lembre-se de que el@ será notificad@ por e-mail.")}</h4>
                            </Col>
                          </Row>
                        </Modal.Header>
                        <Modal.Body className="style-scroll-bar-find">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("Buscar oportunidade...")}
                            onChange={(e) => this.searchOpportunities(e.target.value)}
                          />
                          <br/>
                          <Button
                            variant="outline-warning"
                            style={{ borderRadius: "20px", padding: "3px 25px", marginLeft: "80%" }}
                            onClick={() => this.resetOpportunities()}
                          >
                            Limpar busca
                          </Button>
                          <p className="u-semibold mt-2">{t("Oportunidades")}</p>
                          {this.state.opportunities.map((opp) => (
                            <Button
                              key={opp.id}
                              className={
                                (this.state.selectedId === opp._id
                                  ? "modal-button-selected"
                                  : "modal-button-opp") + " mt-2 qualquer"
                              }
                              onClick={() => this.setState({ selectedId: opp._id })}
                            >
                              {opp.titulo}
                              <Tooltip
                                size="small"
                                type="light"
                                content={`${t("Recrutador(a)")}: ${
                                  opp.user?.displayName
                                }`}
                                placement="top-end"
                              >
                                <InfoIcon className="info" />
                              </Tooltip>
                            </Button>
                          ))}
                        </Modal.Body>
                        <Modal.Footer>
                          <FooterItem>
                            <Button
                              disabled={this.state.selectedId === ""}
                              variant="outline-warning"
                              onClick={() => this.becomeCandidateByRh()}
                              style={{ borderRadius: "20px", padding: "3px 25px" }}
                            >
                              {t("Salvar")}
                            </Button>
                          </FooterItem>
                        </Modal.Footer>
                      </Modal>
                    )
                  }
                  <br />
                  <p className="u-fs-md u-semibold">
                    {t("Processos seletivos")}:
                  </p>
                  <ul className="applied-list ">
                    <Accordion level={4} isBare>
                      {this.state.opportunitiesRelated &&
                        this.state.opportunitiesRelated.inProgressOpportunities.map(
                          (item) => {
                            return (
                              <li>
                                <Accordion.Section>
                                  <Accordion.Header className="accordion-header">
                                    <Accordion.Label className="p-0">
                                      <p className="opportunities-applied mb-2 u-fs-sm">
                                        {item.title}
                                      </p>
                                    </Accordion.Label>
                                  </Accordion.Header>
                                  <Accordion.Panel className="p-0 ml-2">
                                    <button
                                      onClick={() => this.props.history.push(`/opportunity/${item._id}`)}
                                      style={{
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                        textAlign: 'left'
                                      }}
                                    >
                                      <p className="mb-4">
                                        {t("A oportunidade está atualmente em")}{" "}
                                        <span className="u-bold">
                                          {t(item.fase)}
                                        </span>{" "}
                                        {t("e o candidato encontra-se")}{" "}
                                        <span className="u-bold">
                                          {this.getCandidateStatus(item)}
                                        </span>
                                      </p>
                                    </button>
                                  </Accordion.Panel>
                                </Accordion.Section>
                              </li>
                            );
                          }
                        )}
                    </Accordion>
                  </ul>
                </div>
                <div className="inprogress-opportunities mt-4 mb-4">
                  <Accordion
                    level={4}
                    isCompact
                    expandedSections={this.state.expandedSections}
                    onChange={(index) => {
                      if (this.state.expandedSections.includes(index)) {
                        this.setState({
                          expandedSections: this.state.expandedSections.filter(
                            (oldIndex: any) => oldIndex !== index
                          ),
                        });
                      } else {
                        this.setState({
                          expandedSections: [
                            ...this.state.expandedSections,
                            index,
                          ],
                        });
                      }
                    }}
                  >
                    <Accordion.Section>
                      <Accordion.Header className="accordion-header">
                        <Accordion.Label className="p-0">
                          <p
                            style={{ opacity: 0.5 }}
                            className="opportunities-applied mb-2 u-fs-sm"
                          >
                            {t("Processos Anteriores")}:
                          </p>
                        </Accordion.Label>
                      </Accordion.Header>
                      <Accordion.Panel>
                        <ul className="applied-list ">
                          {this.state.opportunitiesRelated &&
                            this.state.opportunitiesRelated.finishedOpportunities.map(
                              (item) => {
                                return (
                                  <Accordion.Section>
                                    <Accordion.Header className="accordion-header">
                                      <Accordion.Label className="p-0">
                                        <p
                                          style={{ opacity: 0.5 }}
                                          className="opportunities-applied mb-2 u-fs-sm"
                                        >
                                          {item.title}:
                                        </p>
                                      </Accordion.Label>
                                    </Accordion.Header>
                                    <Accordion.Panel>
                                      <button
                                        onClick={() => this.props.history.push(`/opportunity/${item._id}`)}
                                        style={{
                                          backgroundColor: 'transparent',
                                          border: 'none',
                                          textAlign: 'left'
                                        }}
                                      >
                                        <p className="mb-4">
                                          {t("Participação encerrada na fase de")}:{" "}
                                          <span className="u-bold">
                                            {this.getStatusOldOpp(item)}
                                          </span>
                                        </p>
                                      </button>
                                    </Accordion.Panel>
                                  </Accordion.Section>
                                );
                              }
                            )}
                        </ul>
                      </Accordion.Panel>
                    </Accordion.Section>
                  </Accordion>
                </div>
              </Col>
              <Col sm={12} lg={9} className="order-first order-md-last pl-4">
                <div className="container-professional-header professional-title mb-4">
                  {this.state.user.userImageUrl ? (
                    <div className="container-img-professional">
                      <img
                        className="professional-picture"
                        src={
                          this.state.user &&
                            this.state.user.userImageUrl &&
                            this.getNewFileStackUrl(this.state.user.userImageUrl)
                            ? this.setUrlImg(this.state.user.userImageUrl)
                            : this.state.user.userImageUrl
                              ? this.state.user.userImageUrl
                              : avatar
                        }
                        alt=""
                      />
                    </div>
                  ) : (
                    <div className="container-img-professional">
                      <img className="professional-picture" src={ReactComponents} alt="" />
                    </div>
                  )}
                  <div className="professional-info">
                    <div className="u-fs-lg u-semibold d-flex-column">
                      {this.state.user.displayName}
                    </div>
                    <span className="update-style">
                      {t("Última atualização")}: {Moment(this.state.user.updated).format(dateFormat)}
                    </span>
                    {this.state.user.description && (
                      <p className="u-fs-md u-semibold">
                        Coordenador de Projetos, Líder de Equipe, PMO, CSO
                      </p>
                    )}
                    {AuthenticationService.getUser().roles.includes("recruitment") && (
                      <div>
                        <Row>
                          <Col textAlign="center">
                            <ThemeProvider theme={theme as any}>
                              <Tooltip
                                size="medium"
                                type="light"
                                placement="top-start"
                                content={
                                  <span>
                                    {t("Ao marcar esta opcao o profissional ganha status de talento e passa a ser exibido no Talent Hunters Kanban!")}
                                  </span>
                                }>
                                <ToggleIconButton
                                  aria-label="star-talent"
                                  isPressed={this.state.isTalent}
                                  onClick={() => this.setState({ isTalent: !this.state.isTalent }, () => {
                                    this.updateUserTalentStatus();
                                  })}
                                >
                                  {
                                    this.state.isTalent
                                      ? <StarFillIcon style={{ color: "#ea951d" }} />
                                      : <StarStrokeIcon style={{ color: "#ea951d" }} />
                                  }

                                </ToggleIconButton>
                              </Tooltip>
                              <Tooltip
                                type="light"
                                size="medium"
                                placement="top-end"
                                content={
                                  <span>
                                    {t("Ao marcar esta opção o profissional ganha status de Eluminer e fará parte do time Elumini")}
                                  </span>
                                }>
                                <ToggleIconButton
                                  aria-label="leaf"
                                  isPressed={this.state.isEluminer}
                                  onClick={() => this.setState({ isEluminer: !this.state.isEluminer }, () => {
                                    this.updateEluminerStatus(this.state.isEluminer);
                                  })}
                                >
                                  {
                                    this.state.isEluminer
                                      ? <HomeFillIcon style={{ color: "#ea951d" }} />
                                      : <HomeStrokeIcon style={{ color: "#ea951d" }} />
                                  }

                                </ToggleIconButton>
                              </Tooltip>
                              <Tooltip
                                type="light"
                                size="medium"
                                placement="top-end"
                                content={
                                  <span>
                                    {t("Marcar com cidadania européia")}
                                  </span>
                                }>
                                <ToggleIconButton
                                  aria-label="Globe-europeanUnionCitizenship"
                                  isPressed={this.state.europeanUnionCitizenship}
                                  onClick={() => this.setState({ europeanUnionCitizenship: !this.state.europeanUnionCitizenship }, () => {
                                    this.updateEuropeanUnionCitizenshipStatus(this.state.europeanUnionCitizenship);
                                  })}
                                >
                                  {
                                    this.state.europeanUnionCitizenship
                                      ? <GlobeFillIcon style={{ color: "#ea951d" }} />
                                      : <GlobeStrokeIcon style={{ color: "#ea951d" }} />
                                  }
                                </ToggleIconButton>
                              </Tooltip>
                            </ThemeProvider>
                          </Col>
                        </Row>
                        <ThemeProvider theme={theme as any}>
                          <div className="field-toggle">
                          </div>
                        </ThemeProvider>
                      </div>
                    )}
                  </div>
                  <button
                    className="button-show-feed"
                    onClick={() => {
                      this.setState({ showFeed: !this.state.showFeed })
                    }}
                  >
                    <LG>
                      <span
                        style={{
                          border: "none",
                          borderRadius: "5px",
                          backgroundColor: "#1f73b733",
                          padding: "1px 10px",
                          fontWeight: "500"
                        }}
                      >
                        Feed
                      </span>
                    </LG>
                    <ToggleIconButton
                      aria-label="feed-buttom"
                      isPressed={this.state.showFeed}
                      onClick={() => this.setState({ showFeed: !this.state.showFeed })}
                    >
                      {
                        this.state.showFeed
                          ? <ChevronDoubleUpStrokeIcon />
                          : <ChevronDoubleDownStrokeIcon />
                      }
                    </ToggleIconButton>
                  </button>
                </div>

                  {this.state.showFeed && (
                    <ProfessionalFeedCard
                      user={this.state.user}
                      policy={this.state.policyAndSignature}
                      eventID={this.getEventID}
                    />
                  )}

                <Grid>
                  {/* <ThemeProvider theme={theme as any}>
                    <Tooltip
                      size="medium"
                      type="light"
                      placement="top-start"
                      content={
                        <span>
                          {t("Ao marcar esta opcao o profissional ganha status de talento e passa a ser exibido no Talent Hunters Kanban!")}
                        </span>
                      }>
                      <ToggleIconButton
                        aria-label="star-talent"
                        isPressed={this.state.edit}
                        onClick={() => this.setState( { edit: !this.state.edit }, () => {
                          this.updateUserTalentStatus();
                        } )}
                      >
                        {
                          this.state.edit
                            ? <PencilFillIcon style={{ color: "#ea951d"}}/>
                            : <PencilStrokeIcon style={{ color: "#ea951d"}} />
                        }
                        
                      </ToggleIconButton>
                    </Tooltip>
                  </ThemeProvider> */}
                  <Row className="justify-content-center">
                    <Col style={{ flexGrow: 0 }}>
                      <IconPhone />
                    </Col>
                    <Col>
                      <Row style={{ marginBottom: "1rem" }}>
                        <Col>
                          <span className="title-area">{t("Contato")}</span>
                        </Col>
                        <Col />
                      </Row>
                      <Row>
                        <Col sm={12} lg={6}>
                          <p className="info-title">
                            {t("Principal")}:{" "}
                            <span className="info-content">
                              {this.state.user.contato.lista[0]?.contato
                                ? this.formatContact(
                                  this.state.user.contato.lista[0].contato,
                                  hasPhoneContactPermission
                                )
                                : t("Sem informação")}
                            </span>
                          </p>
                          {/* <p className="info-title">
                            {t("Principal")}:{" "}
                            <input
                              disabled={this.state.edit}
                              type="text"
                              className="info-content"
                              value={this.state.user.contato?.lista[0]?.contato}
                              onChange={(e) => {
                                const contato = this.state.user.contato;
                                contato.lista[0].contato = e.target.value;
                                this.setState({user: {...this.state.user, contato}})
                              }}
                            />
                          </p> */}
                        </Col>
                        <Col sm={12} lg={6}>
                          <p className="info-title">
                            {t("Residencial")}:{" "}
                            <span className="info-content">
                              {this.state.user?.dadosPessoais?.localidade
                                ? this.state.user.dadosPessoais.localidade
                                : t("Sem informação")}
                            </span>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} lg={6}>
                          <p className="info-title">
                            E-mail:{" "}
                            <span className="info-content">
                              {this.formatEmail(this.state.user.email)}
                            </span>
                          </p>
                          {/* <p className="info-title">
                            E-mail:{" "}
                            <input
                              disabled={this.state.edit}
                              type="text"
                              className="info-content"
                              value={this.state.email}
                              onChange={(e) => {
                                this.setState({email: e.target.value})
                              }}
                            />
                          </p> */}
                        </Col>
                        <Col sm={12} lg={6}>
                          <p className="info-title">
                            {t("Localidade")}:{" "}
                            {this.state.user.dadosPessoais?.geolocation && (
                              <span className="info-content">
                                {
                                  this.state.user.dadosPessoais?.geolocation
                                    .formatted_address
                                }
                              </span>
                            )}
                            {!this.state.user.dadosPessoais?.geolocation && (
                              <span className="info-content">
                                {t("Sem informação")}
                              </span>
                            )}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Grid>
                <Row>
                  <div className="container-professional-expectation">
                    <p className="view-title-salary-cap">{t("URL LinkedIn")}</p>
                    <div className="view-coin-container-pro-profile">
                    <button
                        onClick={() => this.setState({ showUrlLinkedIn: true })}
                        className="view-salary-expectation-style"
                      >
                        {
                          
                          <div className="content-size">
                            <p className="info-title">
                              <span className="info-content">
                                {`${this.state.user.linkedinUrl}`}
                              </span>
                            </p>
                          </div>
                        }
                      </button>
                    </div>
                  </div>
                </Row>
                <p></p>
                <Row>
                  <div className="container-professional-expectation">
                    <p className="view-title-salary-cap">{t("Pretensão Salarial")}</p>
                    <div className="view-coin-container-pro-profile">
                      <button
                        onClick={() => this.setState({ showPretensao: true })}
                        className="view-salary-expectation-style"
                      >
                        {
                          this.state.contractTypeArray.length > 0
                            ? this.state.contractTypeArray.map((tipo: string) => {
                              return (
                                (tipo === "pj" ||
                                  tipo === "clt" ||
                                  tipo === "others") && (
                                  <div className="content-size">
                                    <p className="info-title">
                                      {tipo.toUpperCase()}:{" "}
                                      <span className="info-content">
                                        {`${this.state.user.perfilUsuario.pretensao.tipo[tipo].moeda} - ${this.state.user.perfilUsuario.pretensao.tipo[tipo].valor}`}
                                      </span>
                                    </p>
                                  </div>
                                )
                              );
                            })
                            : <span>{t("Sem Informação")}</span>
                        }
                      </button>
                    </div>
                  </div>
                </Row>
                <Row className="justify-content-center mt-4">
                  <Col style={{ flexGrow: 0 }}>
                    <IconCv />
                  </Col>
                  <Col>
                    <Row style={{ marginBottom: "1rem" }}>
                      <Col>
                        <span className="title-area">{t("Currículo")}(s)</span>
                        {AuthenticationService.getUser()._id ===
                          this.state.user._id || AuthenticationService.isRh() ? (
                          <FilestackReact
                            actionOptions={{
                              accept: [".pdf", ".docx"],
                            }}
                            apikey={process.env.REACT_APP_FILESTACK_API}
                            componentDisplayMode={{
                              customText: "+",
                              customClass: "add-cv",
                            }}
                            clientOptions={{
                              security: {
                                policy: this.state.policyString,
                                signature: this.state.signatureString,
                              }
                            }}
                            onSuccess={this.getFile.bind(this)}
                          />
                        ) : (
                          <button className="add-cv" title="não autorizado">
                            !
                          </button>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      {this.state.attachments.map((item, index) => {
                        return (
                          <Col sm={12} lg={12}>
                            <div className="d-flex align-items-baseline mb-2">
                              {
                                this.state.policyAndSignature.length > 0 && (
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={this.setUrlAttachment(item.url, index)}
                                    className="info-content mr-2"
                                  >
                                    {item.fileName}
                                  </a>
                                )
                              }
                              {item.comment &&
                                item.comment.includes("(addByRh)") ? (
                                <div className="d-flex align-items-center">
                                  <span className="info-content mr-1 mt-2">
                                    <i>{item.comment.replace("(addByRh)", "")}</i>
                                  </span>
                                  {
                                    this.state.attachments.length > 1 &&
                                    <button
                                      disabled={this.state.disabledButton}
                                      className="delete-cv"
                                      onClick={() => this.deleteCv(item)}
                                    >
                                      x
                                    </button>
                                  }
                                </div>
                              ) : (
                                <span className="info-content mr-2">
                                  <i>{item.comment}</i>
                                </span>
                              )}
                              {item.uploadDate &&
                                <span className="info-content mr-1 mt-2">
                                  <i> | Data de upload: {Moment(item.uploadDate).format(dateFormat)}</i>
                                </span>
                              }
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col lg={11}>
                    <Accordion level={4} isExpandable>
                      <Accordion.Section>
                        <Accordion.Header>
                          <Accordion.Label className="p-0 align-items-top d-flex">
                            <IconGraduation />
                            <p className="title-area ml-3">
                              {t("Formação e Idiomas")}
                            </p>
                          </Accordion.Label>
                        </Accordion.Header>
                        <Accordion.Panel>
                          <Row>
                            <Col style={{ flexGrow: 0 }}></Col>
                            <Col>
                              <Row>
                                <Col sm={12} lg={6}>
                                  <p className="info-title">
                                    {t("Formação")}:{" "}
                                    <span className="info-content">
                                      {Object.values(
                                        this.state.user.perfilUsuario.digomais.lista.filter(
                                          (item) => item.subclassif === "courses"
                                        )
                                      )
                                        .map((item) => item.nome)
                                        .join(", ") || t("Sem informação")}
                                    </span>
                                  </p>
                                </Col>
                                <Col sm={12} lg={6}>
                                  <p className="info-title">
                                    {t("Idiomas")}:{" "}
                                    <span className="info-content" dangerouslySetInnerHTML={{
                                      __html: Object.values(
                                        this.state.user.perfilUsuario.digomais.lista.filter(
                                          (item) =>
                                            item.subclassif === "languages"
                                        )
                                      )
                                        .map((item) =>
                                          '<b class="info-title">' + item.nome + '</b> | ' + this.getItemCaption(item.level)
                                        )
                                        .join("<br/>") || t("Sem informação")
                                    }}>

                                    </span>
                                  </p>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Accordion.Panel>
                      </Accordion.Section>
                      <Accordion.Section>
                        <Accordion.Header>
                          <Accordion.Label className="p-0 align-items-top d-flex">
                            <IconAddInformation />
                            <p className="title-area ml-3">
                              {t("Informações Complementares")}
                            </p>
                          </Accordion.Label>
                        </Accordion.Header>
                        <Accordion.Panel>
                          <Row className="justify-content-center">
                            <Col style={{ flexGrow: 0 }}></Col>
                            <Col>
                              <Row>
                                <Col sm={12} lg={6}>
                                  <p className="info-title">
                                    {t("PCD")}:{" "}
                                    <span className="info-content">
                                      {this.state.user.dadosPessoais?.pcdUser
                                        ?.isUserPCD !== undefined
                                        ? this.state.user.dadosPessoais.pcdUser
                                          .isUserPCD
                                          ? t("Sim")
                                          : t("Não")
                                        : t("Sem informação")}
                                    </span>
                                  </p>
                                </Col>
                                <Col sm={12} lg={6}>
                                  <p className="info-title">
                                    {t("Indicação")}:{" "}
                                    <span className="info-content">
                                      {this.state.user.dadosPessoais?.indicadoPor
                                        ? this.state.user.dadosPessoais
                                          .indicadoPor
                                        : t("Sem informação")}
                                    </span>
                                  </p>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Accordion.Panel>
                      </Accordion.Section>
                      <Accordion.Section>
                        <Accordion.Header>
                          <Accordion.Label className="p-0 align-items-top d-flex">
                            <IconCarrer />
                            <p className="title-area ml-3">
                              {t("Carreira e Objetivos Profissionais")}
                            </p>
                          </Accordion.Label>
                        </Accordion.Header>
                        <Accordion.Panel>
                          <Col className="mb-4">
                            <Row style={{ marginBottom: "1rem" }}>
                              <Col>
                                <span className="title-objective">
                                  {t("Onde gostaria de trabalhar")}
                                </span>
                              </Col>
                              <Col />
                            </Row>
                            <Row>
                            <Col sm={12} lg={6}>
                                {Object.values(
                                  this.state.user.perfilUsuario.mosaico.escolhidas.filter(
                                    (item) => item.detalhe.classif === "carreiras"
                                  )
                                ).map((item) => {
                                  return (
                                    <button
                                      className="p-2 mr-2 mb-2"
                                      style={{
                                        backgroundColor: "#EFF2F2 ",
                                        border: "none",
                                        borderRadius: "14px",
                                      }}
                                    >
                                      <span>{t(item.detalhe.nome)}</span>
                                    </button>
                                  );
                                })}
                              </Col>
                            </Row>
                          </Col>
                          <Col className="mb-4">
                            <Row style={{ marginBottom: "1rem" }}>
                              <Col>
                                <span className="title-objective">
                                  {t("Quais são os seus interesses?")}
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={12} lg={6}>
                                {this.state.user.perfilUsuario.mosaico.escolhidas.filter(
                                  (item: any) => item.detalhe.classif === "euquero"
                                ).map(
                                  (item) => {
                                    return (
                                      <button
                                        className="p-2 mr-2 mb-2"
                                        style={{
                                          backgroundColor: "#EFF2F2 ",
                                          border: "none",
                                          borderRadius: "14px",
                                        }}
                                      >
                                        <span>{t(item.detalhe.nome)}</span>
                                      </button>
                                    );
                                  }
                                )}
                              </Col>
                            </Row>
                          </Col>
                          <Col className="mb-4">
                            <Row style={{ marginBottom: "1rem" }}>
                              <Col>
                                <span className="title-objective">
                                  {t("Com o que gostaria de trabalhar?")}
                                </span>
                              </Col>
                              {/* <Col /> */}
                            </Row>
                            <Row>
                              <Col sm={12} lg={6}>
                                {Object.values(
                                  this.state.user.perfilUsuario.mosaico.escolhidas.filter(
                                    (item) => item.detalhe.classif === "carreiras"
                                  )
                                ).map((item) => {
                                  return (
                                    <button
                                      className="p-2 mr-2 mb-2"
                                      style={{
                                        backgroundColor: "#EFF2F2 ",
                                        border: "none",
                                        borderRadius: "14px",
                                      }}
                                    >
                                      <span>{t(item.detalhe.nome)}</span>
                                    </button>
                                  );
                                })}
                              </Col>
                            </Row>
                          </Col>
                          <Col className="mb-4">
                            <Row style={{ marginBottom: "1rem" }}>
                              <Col>
                                <span className="title-objective">
                                  {t("Principais competências")}
                                </span>
                              </Col>
                              <Col />
                            </Row>
                            <Row>
                              <Col sm={12} lg={12}>
                                {Object.values(
                                  this.state.user.perfilUsuario.digomais.lista.filter(
                                    (item) => item.subclassif === "skills"
                                  )
                                ).map((item) => {
                                  return (
                                    <button
                                      className="p-2 mr-2 mb-2"
                                      style={{
                                        backgroundColor: "#EFF2F2 ",
                                        border: "none",
                                        borderRadius: "14px",
                                      }}
                                    >
                                      <span><b>{item.nome}</b> | </span> {this.getItemCaption(item.level)}
                                    </button>
                                  );
                                })}
                              </Col>
                            </Row>
                          </Col>
                        </Accordion.Panel>
                      </Accordion.Section>
                      <Accordion.Section>
                        <Accordion.Header>
                          <Accordion.Label className="p-0 align-items-top d-flex">
                            <IconBehavior />
                            <p className="title-area ml-3">
                              {t("Parecer Técnico")}
                            </p>
                          </Accordion.Label>
                        </Accordion.Header>
                        <Accordion.Panel>
                          {(this.state.avaliacoesUsuario.length > 0 &&
                            this.state.avaliacoesUsuario.find(x => x.opportunity !== undefined))
                            ? (this.state.avaliacoesUsuario.map((avaliacao) => {
                              return (
                                <TechnicalAdvicePanel
                                  opportunityId={avaliacao.opportunity?.id}
                                  userAvaliation={avaliacao}
                                  user={this.state.user}
                                />
                              )
                            }))
                            : (
                              <Accordion.Panel>
                                <span className="info-content">
                                  {t("Sem informação")}
                                </span>
                              </Accordion.Panel>
                            )
                          }
                        </Accordion.Panel>
                      </Accordion.Section>
                      <Accordion.Section>
                        <Accordion.Header>
                          <Accordion.Label className="p-0 align-items-top d-flex">
                            <IconBehavior />
                            <p className="title-area ml-3">
                              {t("Parecer Comportamental")}
                            </p>
                          </Accordion.Label>
                        </Accordion.Header>
                        {(this.state.avaliacoesUsuario.length > 0
                          && this.state.avaliacoesUsuario.find(x => x.opportunity === undefined))
                          ? (this.state.avaliacoesUsuario.map((avaliacao) => {

                            if (avaliacao.opportunity !== undefined) {
                              return (<></>);
                            }

                            const newText = this.changeCssStyle(
                              avaliacao.conteudo
                            );
                            let htmlParse;
                            if (newText !== null) {
                              htmlParse = new DOMParser().parseFromString(
                                newText,
                                "text/html"
                              );
                            } else {
                              htmlParse = new DOMParser().parseFromString(
                                t("Sem informação"),
                                "text/html"
                              );
                            }
                            const parseDataAvaliacao = Date.parse(
                              avaliacao.created
                            );
                            const date = new Date(parseDataAvaliacao);
                            return (
                              <Accordion.Panel className="parecer-comportamental-header">
                                <Row>
                                  <Col
                                    size={6}
                                    style={{ borderBottom: "solid 1px #869baf" }}
                                    className="pb-2"
                                  >
                                    <span>{`Nota: ${avaliacao.resultado}`}</span>
                                  </Col>
                                  <Col
                                    size={6}
                                    style={{ borderBottom: "solid 1px #869baf" }}
                                    className="pb-2 text-right"
                                  >
                                    <span>
                                      {`Data: ${date.getDate()}/${date.getMonth() + 1
                                        }/${date.getUTCFullYear()} | ${avaliacao.user?.displayName
                                        }`}
                                    </span>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="pt-2">
                                    <section
                                      className="text-behavioral"
                                      dangerouslySetInnerHTML={{
                                        __html: htmlParse.body.innerHTML,
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </Accordion.Panel>
                            );
                          })
                          ) : (
                            <Accordion.Panel>
                              <span className="info-content">
                                {t("Sem informação")}
                              </span>
                            </Accordion.Panel>
                          )}
                      </Accordion.Section>
                    </Accordion>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="text-right mb-4"></div>
          {/* MODAL DE COMENTÁRIO NO CURRÍCULO*/}
          <Modal show={this.state.showModal} onHide={() => {
            this.setState({
              showModal: false
            })
          }}>
            <Modal.Header className="d-flex justify-content-center">
              <h4>{t("Arquivo enviado com sucesso")}!</h4>
            </Modal.Header>
            <Modal.Body className="d-flex flex-column">
              <p className="text-center">
                {t("Gostaria de adicionar uma descrição ao arquivo")}?
              </p>
              <label>
                <input
                  className="form-control"
                  type="text"
                  value={this.state.comments}
                  name="commentField"
                  placeholder={t("Descrição")}
                  onChange={(e) => this.setState({ comments: e.target.value })}
                />
              </label>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-around">
              <Button
                variant="secondary"
                disabled={this.state.disabledButton}
                onClick={this.uploadCvFile.bind(this)}
                style={{ width: "35%" }}
              >
                {t("Cancelar")}
              </Button>
              <Button
                variant="primary"
                disabled={this.state.disabledButton}
                onClick={this.uploadCvFile.bind(this)}
                style={{ width: "35%" }}
              >
                {t("Adicionar")}
              </Button>
            </Modal.Footer>
          </Modal>
        
          {/* MODAL PARA EXCLUIR CURRÍCULO */}
          <Modal show={this.state.showModalRemoveCv} onHide={() => {
            this.setState({
              showModalRemoveCv: false
            })
          }}>
            <Modal.Header className="d-flex justify-content-center">
              <h4>{t("Excluir Arquivo")}!</h4>
            </Modal.Header>
            <Modal.Body>
              {t("Deseja realmente excluir este arquivo?")}
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-around">
              <Button
                variant="secondary"
                onClick={() => this.setState({ showModalRemoveCv: false })}
              >
                {t("Cancelar")}
              </Button>
              <Button
                variant="danger"
                disabled={this.state.disabledButton}
                onClick={() => this.deleteCv(this.state.deleteId)}
              >
                {t("Excluir")}
              </Button>
            </Modal.Footer>
          </Modal>

          {/* MODAL PARA EDICAO URL LINKEDIN */}
          <Modal show={this.state.showUrlLinkedIn} onHide={() => {
            this.setState({
              showModalRemoveCv: false
            })
          }}>
            <Modal.Header className="d-flex justify-content-center">
              <h4>{t("Editar URL Perfil do LinkedIn")}</h4>
            </Modal.Header>
            <Modal.Body>
            <Field>
              <Label style={{ width: "100%" }} className="text-label">
                <span className="text-label">{t("URL LinkedIn")}</span>
                <Input
                  value={this.state.user.linkedinUrl}
                  name="linkedinUrl"
                  className="mt-2"
                  onChange={this.handleChange}
                />
              </Label>
            </Field>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-around">
              <div className="d-flex flex-column h-100">
                <div className="mb-auto"></div>
                <Row className="w-100 d-flex justify-content-end mt-2">
                  <Button
                    className="view-button-save-expectation"
                    onClick={() => {
                      this.updateSalaryExpectation(this.state.user)
                      this.setState({ showUrlLinkedIn: false })
                    }}
                  >
                    {t("Salvar")}
                  </Button>
                </Row>
              </div>
            </Modal.Footer>
          </Modal>

          <Modal
            show={this.state.showPretensao}
            size="lg"
            centered
            onHide={() => this.setState({ showPretensao: false })}
          >
            <Modal.Header>
              <LabelForm
                style={{
                  fontSize: '1rem',
                  textAlign: 'center',
                  marginTop: '0.5rem',
                }}
              >
                {t("Escolha a pretensão conforme o tipo de contratação")}
              </LabelForm>
              <button
                onClick={() => this.setState({ showPretensao: false })}
                className='view-close-modal-pretensao'
              >
                &times;
              </button>
            </Modal.Header>
            <Modal.Body>
              {
                this.state.contractTypeArray.map((tipo: string) => {
                  return (
                    (tipo === "pj" ||
                      tipo === "clt" ||
                      tipo === "others") && (
                      <ThemeProvider theme={theme as any}>
                        <LabelForm className="mt-2 ml-2">
                          {tipo.toUpperCase()}:
                        </LabelForm>
                        <div className="view-coin-container-pro-profile">
                          <div className="view-coin-field">
                            <Dropdown
                              selectedItem={this.state.user.perfilUsuario.pretensao.tipo[tipo].moeda}
                              onSelect={(value: any) => {
                                const perfilUsuario =
                                  this.state.user.perfilUsuario;
                                perfilUsuario.pretensao.tipo[tipo].moeda =
                                  value.label;
                                this.setState({
                                  user: {
                                    ...this.state.user,
                                    perfilUsuario,
                                  },
                                })
                              }}
                              downshiftProps={{
                                itemToString: (item: any) => item && item.label,
                              }}
                            >
                              <DropField className="view-select-button-prof-profile">
                                <Select>
                                  <label className="view-label-select-prof-profile">
                                    {t("Moeda")}:
                                  </label>
                                  <p className="mt-3">
                                    {tipo === "pj" ? this.state.user.perfilUsuario.pretensao.tipo.pj.moeda : ""}
                                    {tipo === "clt" ? this.state.user.perfilUsuario.pretensao.tipo.clt.moeda : ""}
                                    {tipo === "others" ? this.state.user.perfilUsuario.pretensao.tipo.others.moeda : ""}
                                  </p>
                                </Select>
                              </DropField>
                              <Menu
                                popperModifiers={{
                                  preventOverflow: {
                                    boundariesElement: "viewport",
                                  },
                                }}
                              >
                                {coinType.map((item: any) => (
                                  <Item
                                    className="d-flex"
                                    key={item.value}
                                    value={item}
                                  >
                                    {item.label}
                                  </Item>
                                ))}
                              </Menu>
                            </Dropdown>
                          </div>
                          <div className="view-price-field">
                            <Dropdown
                              selectedItem={this.state.user.perfilUsuario.pretensao.tipo[tipo].valor}
                              onSelect={(value: any) => {
                                const perfilUsuario =
                                  this.state.user.perfilUsuario;
                                perfilUsuario.pretensao.tipo[tipo].valor =
                                  value.label;
                                this.setState({
                                  user: {
                                    ...this.state.user,
                                    perfilUsuario,
                                  },
                                });
                              }}
                              downshiftProps={{
                                itemToString: (item: any) => item && item.label,
                              }}
                            >
                              <DropField className="view-select-button-prof-profile">
                                <Select>
                                  <label className="view-label-select-prof-profile">
                                    {t("Faixa")}:
                                  </label>
                                  <p className="mt-3">
                                    {tipo === "pj" ? this.state.user.perfilUsuario.pretensao.tipo.pj.valor : ""}
                                    {tipo === "clt" ? this.state.user.perfilUsuario.pretensao.tipo.clt.valor : ""}
                                    {tipo === "others" ? this.state.user.perfilUsuario.pretensao.tipo.others.valor : ""}
                                  </p>
                                </Select>
                              </DropField>
                              <Menu
                                popperModifiers={{
                                  preventOverflow: {
                                    boundariesElement: "viewport",
                                  },
                                }}
                              >
                                {priceRange.map((item: any) => (
                                  <Item
                                    className="d-flex"
                                    key={item.value}
                                    value={item}
                                  >
                                    {item.label}
                                  </Item>
                                ))}
                              </Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </ThemeProvider>
                    ))
                })
              }
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex flex-column h-100">
                <div className="mb-auto"></div>
                <Row className="w-100 d-flex justify-content-end mt-2">
                  <Button
                    className="view-button-save-expectation"
                    onClick={() => {
                      this.updateSalaryExpectation(this.state.user)
                      this.setState({ showPretensao: false })
                    }}
                  >
                    {t("Salvar")}
                  </Button>
                </Row>
              </div>
            </Modal.Footer>
          </Modal>
        </div>

        {AuthenticationService.getUser().roles.includes("recruitment") && (
        <div className="container mt-4">
          <div>
            <Row>
              <Col
                size={12}
                style={{ borderBottom: "solid 1px #354A5F", opacity: 0.2 }}
                className="mb-4 mt-1"
              />
            </Row>
            <Row>
              <Col className="body-left" sm={12} lg={3}>
                <div
                  ref={this.footerRef}
                >
                  {this.state.user && this.state.user.perfilUsuario && (
                    <MosaicPreview
                      scale={this.state.width / 550}
                      mosaic={this.state.user.perfilUsuario.mosaico.escolhidas}
                    ></MosaicPreview>
                  )}
                </div>
              </Col>
              <Col sm={12} lg={9} className="order-first order-md-last pl-4">
                <div className="container-professional-header professional-title mb-4">
                  <div className="professional-info">
                    <span className="u-semibold">
                        FICHA DO RECRUTADOR
                    </span>
                  </div>
                  <br />
                  <div className="professional-info">
 
                      <span className="update-style">
                        {t("Última atualização")}: {Moment(this.state.user.recruiterInputs.lastUpdated).format(dateFormat)}
                      </span>
                   
                  </div>
                </div>

                <Grid>
                  <Row className="justify-content-center">
                    <Col style={{ flexGrow: 0 }}>
                      <IconPhone />
                    </Col>
                    <Col>
                      <Row style={{ marginBottom: "1rem" }}>
                        <Col>
                          <span className="title-area">{t("Contato")}</span>
                        </Col>
                        <Col />
                      </Row>
                      <Row>
                        <Col sm={12} lg={6}>
                          <p className="info-title">
                            {t("Principal")}:{" "}
                          </p>
                          <div>
                            <textarea 
                              style={{width: "100%"}}
                              value={this.state.user.recruiterInputs?.contato?.principal}
                              onChange={(event) => {
                                this.setState({
                                  user: {
                                    ...this.state.user,
                                    recruiterInputs: {
                                      ...this.state.user.recruiterInputs,
                                      contato: {
                                        ...this.state.user.recruiterInputs?.contato,
                                        principal: event.target.value
                                      }
                                    }
                                  }
                                });
                              }}
                            />
                          </div>
                        </Col>
                        <Col sm={12} lg={6}>
                          <p className="info-title">
                            {t("Residencial")}:{" "}
                          </p>
                          <div>
                          <textarea 
                              style={{width: "100%"}}
                              value={this.state.user.recruiterInputs?.contato?.residencial}
                              onChange={(event) => {
                                this.setState({
                                  user: {
                                    ...this.state.user,
                                    recruiterInputs: {
                                      ...this.state.user.recruiterInputs,
                                      contato: {
                                        ...this.state.user.recruiterInputs?.contato,
                                        residencial: event.target.value
                                      }
                                    }
                                  }
                                });
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} lg={6}>
                          <p className="info-title">
                            E-mail:{" "}
                          </p>
                          <div>
                            <textarea 
                                style={{width: "100%"}}
                                value={this.state.user.recruiterInputs?.contato?.email}
                                onChange={(event) => {
                                  this.setState({
                                    user: {
                                      ...this.state.user,
                                      recruiterInputs: {
                                        ...this.state.user.recruiterInputs,
                                        contato: {
                                          ...this.state.user.recruiterInputs?.contato,
                                          email: event.target.value
                                        }
                                      }
                                    }
                                  });
                                }}
                              />
                          </div>
                        </Col>
                        <Col sm={12} lg={6}>
                          <p className="info-title">
                            {t("Localidade")}:{" "}
                          </p>
                          <div>
                          <textarea 
                                style={{width: "100%"}}
                                value={this.state.user.recruiterInputs?.contato?.localidade}
                                onChange={(event) => {
                                  this.setState({
                                    user: {
                                      ...this.state.user,
                                      recruiterInputs: {
                                        ...this.state.user.recruiterInputs,
                                        contato: {
                                          ...this.state.user.recruiterInputs?.contato,
                                          localidade: event.target.value
                                        }
                                      }
                                    }
                                  });
                                }}
                              />
                          </div>
                        </Col>
                        <Col sm={12} lg={6}>
                          <p className="info-title">
                            {t("LinkedIn")}:{" "}
                          </p>
                          <div>
                          <textarea 
                                style={{width: "100%"}}
                                value={this.state.user.recruiterInputs?.linkedinUrl}
                                onChange={(event) => {
                                  this.setState({
                                    user: {
                                      ...this.state.user,
                                      recruiterInputs: {
                                        ...this.state.user.recruiterInputs,
                                        linkdeinUrl: event.target.value
                                      }
                                    }
                                  });
                                }}
                              />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Grid>
                <Row className="mt-4">
                  <Col lg={11}>
                    <Accordion level={4} isExpandable>
                    <Accordion.Section>
                        <Accordion.Header>
                          <Accordion.Label className="p-0 align-items-top d-flex">
                            <IconCarrer />
                            <p className="title-area ml-3">
                              {t("Pretensão Salarial")}
                            </p>
                          </Accordion.Label>
                        </Accordion.Header>
                        <Accordion.Panel>
                          <Row>
                            <Col style={{ flexGrow: 0 }}></Col>
                            <Col>
                              <Row>
                                <Col sm={12} lg={6}>
                                {  
                                  this.state.contractTypeArray.map((tipo: string) => {
                                    return (
                                      (tipo === "pj" ||
                                        tipo === "clt" ||
                                        tipo === "others") && (
                                        <ThemeProvider theme={theme as any}>
                                          <LabelForm className="mt-2 ml-2">
                                            {tipo.toUpperCase()}:
                                          </LabelForm>
                                          <div className="view-coin-container-pro-profile">
                                            <div className="view-coin-field">
                                              <Dropdown
                                                selectedItem={this.state.user.recruiterInputs?.pretensao?.tipo[tipo]?.moeda}
                                                onSelect={(value: any) => {
                                                  const recruiterInputs =
                                                    this.state.user.recruiterInputs;
                                                    recruiterInputs.pretensao.tipo[tipo].moeda =
                                                    value.label;
                                                  this.setState({
                                                    user: {
                                                      ...this.state.user,
                                                      recruiterInputs,
                                                    },
                                                  })
                                                }}
                                                downshiftProps={{
                                                  itemToString: (item: any) => item && item.label,
                                                }}
                                              >
                                                <DropField className="view-select-button-prof-profile">
                                                  <Select>
                                                    <label className="view-label-select-prof-profile">
                                                      {t("Moeda")}:
                                                    </label>
                                                    <p className="mt-3">
                                                      {tipo === "pj" ? this.state.user.recruiterInputs.pretensao.tipo.pj.moeda : ""}
                                                      {tipo === "clt" ? this.state.user.recruiterInputs.pretensao.tipo.clt.moeda : ""}
                                                      {tipo === "others" ? this.state.user.recruiterInputs.pretensao.tipo.others.moeda : ""}
                                                    </p>
                                                  </Select>
                                                </DropField>
                                                <Menu
                                                  popperModifiers={{
                                                    preventOverflow: {
                                                      boundariesElement: "viewport",
                                                    },
                                                  }}
                                                >
                                                  {coinType.map((item: any) => (
                                                    <Item
                                                      className="d-flex"
                                                      key={item.value}
                                                      value={item}
                                                    >
                                                      {item.label}
                                                    </Item>
                                                  ))}
                                                </Menu>
                                              </Dropdown>
                                            </div>
                                            <div className="view-price-field">
                                              <Dropdown
                                                selectedItem={this.state.user.recruiterInputs.pretensao.tipo[tipo].valor}
                                                onSelect={(value: any) => {
                                                  const recruiterInputs =
                                                    this.state.user.recruiterInputs;
                                                  recruiterInputs.pretensao.tipo[tipo].valor =
                                                    value.label;
                                                  this.setState({
                                                    user: {
                                                      ...this.state.user,
                                                      recruiterInputs,
                                                    },
                                                  });
                                                }}
                                                downshiftProps={{
                                                  itemToString: (item: any) => item && item.label,
                                                }}
                                              >
                                                <DropField className="view-select-button-prof-profile">
                                                  <Select>
                                                    <label className="view-label-select-prof-profile">
                                                      {t("Faixa")}:
                                                    </label>
                                                    <p className="mt-3">
                                                      {tipo === "pj" ? this.state.user.recruiterInputs.pretensao.tipo.pj.valor : ""}
                                                      {tipo === "clt" ? this.state.user.recruiterInputs.pretensao.tipo.clt.valor : ""}
                                                      {tipo === "others" ? this.state.user.recruiterInputs.pretensao.tipo.others.valor : ""}
                                                    </p>
                                                  </Select>
                                                </DropField>
                                                <Menu
                                                  popperModifiers={{
                                                    preventOverflow: {
                                                      boundariesElement: "viewport",
                                                    },
                                                  }}
                                                >
                                                  {priceRange.map((item: any) => (
                                                    <Item
                                                      className="d-flex"
                                                      key={item.value}
                                                      value={item}
                                                    >
                                                      {item.label}
                                                    </Item>
                                                  ))}
                                                </Menu>
                                              </Dropdown>
                                            </div>
                                          </div>
                                        </ThemeProvider>
                                      ))
                                  })
                                }
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Accordion.Panel>
                      </Accordion.Section>
                      <Accordion.Section>
                        <Accordion.Header>
                          <Accordion.Label className="p-0 align-items-top d-flex">
                            <IconGraduation />
                            <p className="title-area ml-3">
                              {t("Formação e Idiomas")}
                            </p>
                          </Accordion.Label>
                        </Accordion.Header>
                        <Accordion.Panel>
                          <Row>
                            <Col style={{ flexGrow: 0 }}></Col>
                            <Col>
                              <Row>
                                <Col sm={12} lg={6}>
                                  <p className="info-title">
                                    {t("Formação")}:{" "}
                                  </p>
                                  <div>
                                    <textarea 
                                      style={{width: "100%"}}
                                      value={this.state.user.recruiterInputs?.formacaoEIdiomas?.formacao}
                                      onChange={(event) => {
                                        this.setState({
                                          user: {
                                            ...this.state.user,
                                            recruiterInputs: {
                                              ...this.state.user.recruiterInputs,
                                              formacaoEIdiomas: {
                                                ...this.state.user.recruiterInputs?.formacaoEIdiomas,
                                                formacao: event.target.value
                                              }
                                            }
                                          }
                                        });
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col sm={12} lg={6}>
                                  <p className="info-title">
                                    {t("Idiomas")}:{" "}
                                  </p>
                                  <div>
                                    <textarea 
                                        style={{width: "100%"}}
                                        value={this.state.user.recruiterInputs?.formacaoEIdiomas?.idiomas}
                                        onChange={(event) => {
                                          this.setState({
                                            user: {
                                              ...this.state.user,
                                              recruiterInputs: {
                                                ...this.state.user.recruiterInputs,
                                                formacaoEIdiomas: {
                                                  ...this.state.user.recruiterInputs?.formacaoEIdiomas,
                                                  idiomas: event.target.value
                                                }
                                              }
                                            }
                                          });
                                        }}
                                      />  
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Accordion.Panel>
                      </Accordion.Section>
                      <Accordion.Section>
                        <Accordion.Header>
                          <Accordion.Label className="p-0 align-items-top d-flex">
                            <IconAddInformation />
                            <p className="title-area ml-3">
                              {t("Informações Complementares")}
                            </p>
                          </Accordion.Label>
                        </Accordion.Header>
                        <Accordion.Panel>
                          <Row className="justify-content-center">
                            <Col style={{ flexGrow: 0 }}></Col>
                            <Col>
                              <Row>
                                <Col sm={12} lg={6}>
                                  <p className="info-title">
                                    {t("PCD")}:{" "}
                                  </p>
                                  <div>
                                  <textarea 
                                        style={{width: "100%"}}
                                        value={this.state.user.recruiterInputs?.infosComplementares?.pcd}
                                        onChange={(event) => {
                                          this.setState({
                                            user: {
                                              ...this.state.user,
                                              recruiterInputs: {
                                                ...this.state.user.recruiterInputs,
                                                infosComplementares: {
                                                  ...this.state.user.recruiterInputs?.infosComplementares,
                                                  pcd: event.target.value
                                                }
                                              }
                                            }
                                          });
                                        }}
                                      /> 
                                  </div>
                                </Col>
                                <Col sm={12} lg={6}>
                                  <p className="info-title">
                                    {t("Indicação")}:{" "}
                                  </p>
                                  <div>
                                  <textarea 
                                        style={{width: "100%"}}
                                        value={this.state.user.recruiterInputs?.infosComplementares?.indicacao}
                                        onChange={(event) => {
                                          this.setState({
                                            user: {
                                              ...this.state.user,
                                              recruiterInputs: {
                                                ...this.state.user.recruiterInputs,
                                                infosComplementares: {
                                                  ...this.state.user.recruiterInputs?.infosComplementares,
                                                  indicacao: event.target.value
                                                }
                                              }
                                            }
                                          });
                                        }}
                                      />
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Accordion.Panel>
                      </Accordion.Section>
                      <Accordion.Section>
                        <Accordion.Header>
                          <Accordion.Label className="p-0 align-items-top d-flex">
                            <IconCarrer />
                            <p className="title-area ml-3">
                              {t("Carreira e Objetivos Profissionais")}
                            </p>
                          </Accordion.Label>
                        </Accordion.Header>
                        <Accordion.Panel>
                          <Col className="mb-4">
                            <Row style={{ marginBottom: "1rem" }}>
                              <Col>
                                <span className="title-objective">
                                  {t("Onde gostaria de trabalhar?")}
                                </span>
                                <div>
                                  <textarea 
                                    style={{width: "100%"}}
                                    value={this.state.user.recruiterInputs?.carreiraEObjetivos?.ondeGostariaDeTrabalhar}
                                    onChange={(event) => {
                                      this.setState({
                                        user: {
                                          ...this.state.user,
                                          recruiterInputs: {
                                            ...this.state.user.recruiterInputs,
                                            carreiraEObjetivos: {
                                              ...this.state.user.recruiterInputs?.carreiraEObjetivos,
                                              ondeGostariaDeTrabalhar: event.target.value
                                            }
                                          }
                                        }
                                      });
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col className="mb-4">
                            <Row style={{ marginBottom: "1rem" }}>
                              <Col>
                                <span className="title-objective">
                                  {t("Quais são os seus interesses?")}
                                </span>
                                <div>
                                <textarea 
                                    style={{width: "100%"}}
                                    value={this.state.user.recruiterInputs?.carreiraEObjetivos?.interesses}
                                    onChange={(event) => {
                                      this.setState({
                                        user: {
                                          ...this.state.user,
                                          recruiterInputs: {
                                            ...this.state.user.recruiterInputs,
                                            carreiraEObjetivos: {
                                              ...this.state.user.recruiterInputs?.carreiraEObjetivos,
                                              interesses: event.target.value
                                            }
                                          }
                                        }
                                      });
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col className="mb-4">
                            <Row style={{ marginBottom: "1rem" }}>
                              <Col>
                                <span className="title-objective">
                                  {t("Com o que gostaria de trabalhar?")}
                                </span>
                                <div>
                                <textarea 
                                    style={{width: "100%"}}
                                    value={this.state.user.recruiterInputs?.carreiraEObjetivos?.comOQueGostariaDeTrabalhar}
                                    onChange={(event) => {
                                      this.setState({
                                        user: {
                                          ...this.state.user,
                                          recruiterInputs: {
                                            ...this.state.user.recruiterInputs,
                                            carreiraEObjetivos: {
                                              ...this.state.user.recruiterInputs?.carreiraEObjetivos,
                                              comOQueGostariaDeTrabalhar: event.target.value
                                            }
                                          }
                                        }
                                      });
                                    }}
                                  />
                                </div>
                              </Col>
                              {/* <Col /> */}
                            </Row>
                            <Row>
                              <Col sm={12} lg={6}>
                                {Object.values(
                                  this.state.user.perfilUsuario.mosaico.escolhidas.filter(
                                    (item) => item.detalhe.classif === "carreiras"
                                  )
                                ).map((item) => {
                                  return (
                                    <button
                                      className="p-2 mr-2 mb-2"
                                      style={{
                                        backgroundColor: "#EFF2F2 ",
                                        border: "none",
                                        borderRadius: "14px",
                                      }}
                                    >
                                      <span>{t(item.detalhe.nome)}</span>
                                    </button>
                                  );
                                })}
                              </Col>
                            </Row>
                          </Col>
                          <Col className="mb-4">
                            <Row style={{ marginBottom: "1rem" }}>
                              <Col>
                                <span className="title-objective">
                                  {t("Principais competências")}
                                </span>
                                <div>
                                <textarea 
                                    style={{width: "100%"}}
                                    value={this.state.user.recruiterInputs?.carreiraEObjetivos?.principaisCompetencias}
                                    onChange={(event) => {
                                      this.setState({
                                        user: {
                                          ...this.state.user,
                                          recruiterInputs: {
                                            ...this.state.user.recruiterInputs,
                                            carreiraEObjetivos: {
                                              ...this.state.user.recruiterInputs?.carreiraEObjetivos,
                                              principaisCompetencias: event.target.value
                                            }
                                          }
                                        }
                                      });
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={12} lg={12}>
                                {Object.values(
                                  this.state.user.perfilUsuario.digomais.lista.filter(
                                    (item) => item.subclassif === "skills"
                                  )
                                ).map((item) => {
                                  return (
                                    <button
                                      className="p-2 mr-2 mb-2"
                                      style={{
                                        backgroundColor: "#EFF2F2 ",
                                        border: "none",
                                        borderRadius: "14px",
                                      }}
                                    >
                                      <span><b>{item.nome}</b> | </span> {this.getItemCaption(item.level)}
                                    </button>
                                  );
                                })}
                              </Col>
                            </Row>
                          </Col>
                        </Accordion.Panel>
                      </Accordion.Section>
                    </Accordion>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="w-100 d-flex justify-content-end mt-2">
              <Button
                className="view-button-save-expectation"
                onClick={() => {
                  this.updateRecruiterInputs(this.state.user);
                }}
              >
                {t("Salvar")}
              </Button>
            </Row> 
          </div>
          <div className="text-right mb-4"></div>
          {/* MODAL DE COMENTÁRIO NO CURRÍCULO*/}
          <Modal show={this.state.showModal} onHide={() => {
            this.setState({
              showModal: false
            })
          }}>
            <Modal.Header className="d-flex justify-content-center">
              <h4>{t("Arquivo enviado com sucesso")}!</h4>
            </Modal.Header>
            <Modal.Body className="d-flex flex-column">
              <p className="text-center">
                {t("Gostaria de adicionar uma descrição ao arquivo")}?
              </p>
              <label>
                <input
                  className="form-control"
                  type="text"
                  value={this.state.comments}
                  name="commentField"
                  placeholder={t("Descrição")}
                  onChange={(e) => this.setState({ comments: e.target.value })}
                />
              </label>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-around">
              <Button
                variant="secondary"
                disabled={this.state.disabledButton}
                onClick={this.uploadCvFile.bind(this)}
                style={{ width: "35%" }}
              >
                {t("Cancelar")}
              </Button>
              <Button
                variant="primary"
                disabled={this.state.disabledButton}
                onClick={this.uploadCvFile.bind(this)}
                style={{ width: "35%" }}
              >
                {t("Adicionar")}
              </Button>
            </Modal.Footer>
          </Modal>
          
          {/* MODAL PARA EXCLUIR CURRÍCULO */}
          <Modal show={this.state.showModalRemoveCv} onHide={() => {
            this.setState({
              showModalRemoveCv: false
            })
          }}>
            <Modal.Header className="d-flex justify-content-center">
              <h4>{t("Excluir Arquivo")}!</h4>
            </Modal.Header>
            <Modal.Body>
              {t("Deseja realmente excluir este arquivo?")}
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-around">
              <Button
                variant="secondary"
                onClick={() => this.setState({ showModalRemoveCv: false })}
              >
                {t("Cancelar")}
              </Button>
              <Button
                variant="danger"
                disabled={this.state.disabledButton}
                onClick={() => this.deleteCv(this.state.deleteId)}
              >
                {t("Excluir")}
              </Button>
            </Modal.Footer>
          </Modal>
  
          {/* MODAL PARA EDICAO URL LINKEDIN */}
          <Modal show={this.state.showUrlLinkedIn} onHide={() => {
            this.setState({
              showModalRemoveCv: false
            })
          }}>
            <Modal.Header className="d-flex justify-content-center">
              <h4>{t("Editar URL Perfil do LinkedIn")}</h4>
            </Modal.Header>
            <Modal.Body>
            <Field>
              <Label style={{ width: "100%" }} className="text-label">
                <span className="text-label">{t("URL LinkedIn")}</span>
                <Input
                  value={this.state.user.linkedinUrl}
                  name="linkedinUrl"
                  className="mt-2"
                  onChange={this.handleChange}
                />
              </Label>
            </Field>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-around">
              <div className="d-flex flex-column h-100">
                <div className="mb-auto"></div>
                <Row className="w-100 d-flex justify-content-end mt-2">
                  <Button
                    className="view-button-save-expectation"
                    onClick={() => {
                      this.updateSalaryExpectation(this.state.user)
                      this.setState({ showUrlLinkedIn: false })
                    }}
                  >
                    {t("Salvar")}
                  </Button>
                </Row>
              </div>
            </Modal.Footer>
          </Modal>
  
          <Modal
            show={this.state.showPretensao}
            size="lg"
            centered
            onHide={() => this.setState({ showPretensao: false })}
          >
            <Modal.Header>
              <LabelForm
                style={{
                  fontSize: '1rem',
                  textAlign: 'center',
                  marginTop: '0.5rem',
                }}
              >
                {t("Escolha a pretensão conforme o tipo de contratação")}
              </LabelForm>
              <button
                onClick={() => this.setState({ showPretensao: false })}
                className='view-close-modal-pretensao'
              >
                &times;
              </button>
            </Modal.Header>
            <Modal.Body>
              {
                this.state.contractTypeArray.map((tipo: string) => {
                  return (
                    (tipo === "pj" ||
                      tipo === "clt" ||
                      tipo === "others") && (
                      <ThemeProvider theme={theme as any}>
                        <LabelForm className="mt-2 ml-2">
                          {tipo.toUpperCase()}:
                        </LabelForm>
                        <div className="view-coin-container-pro-profile">
                          <div className="view-coin-field">
                            <Dropdown
                              selectedItem={this.state.user.perfilUsuario.pretensao.tipo[tipo].moeda}
                              onSelect={(value: any) => {
                                const perfilUsuario =
                                  this.state.user.perfilUsuario;
                                perfilUsuario.pretensao.tipo[tipo].moeda =
                                  value.label;
                                this.setState({
                                  user: {
                                    ...this.state.user,
                                    perfilUsuario,
                                  },
                                })
                              }}
                              downshiftProps={{
                                itemToString: (item: any) => item && item.label,
                              }}
                            >
                              <DropField className="view-select-button-prof-profile">
                                <Select>
                                  <label className="view-label-select-prof-profile">
                                    {t("Moeda")}:
                                  </label>
                                  <p className="mt-3">
                                    {tipo === "pj" ? this.state.user.perfilUsuario.pretensao.tipo.pj.moeda : ""}
                                    {tipo === "clt" ? this.state.user.perfilUsuario.pretensao.tipo.clt.moeda : ""}
                                    {tipo === "others" ? this.state.user.perfilUsuario.pretensao.tipo.others.moeda : ""}
                                  </p>
                                </Select>
                              </DropField>
                              <Menu
                                popperModifiers={{
                                  preventOverflow: {
                                    boundariesElement: "viewport",
                                  },
                                }}
                              >
                                {coinType.map((item: any) => (
                                  <Item
                                    className="d-flex"
                                    key={item.value}
                                    value={item}
                                  >
                                    {item.label}
                                  </Item>
                                ))}
                              </Menu>
                            </Dropdown>
                          </div>
                          <div className="view-price-field">
                            <Dropdown
                              selectedItem={this.state.user.perfilUsuario.pretensao.tipo[tipo].valor}
                              onSelect={(value: any) => {
                                const perfilUsuario =
                                  this.state.user.perfilUsuario;
                                perfilUsuario.pretensao.tipo[tipo].valor =
                                  value.label;
                                this.setState({
                                  user: {
                                    ...this.state.user,
                                    perfilUsuario,
                                  },
                                });
                              }}
                              downshiftProps={{
                                itemToString: (item: any) => item && item.label,
                              }}
                            >
                              <DropField className="view-select-button-prof-profile">
                                <Select>
                                  <label className="view-label-select-prof-profile">
                                    {t("Faixa")}:
                                  </label>
                                  <p className="mt-3">
                                    {tipo === "pj" ? this.state.user.perfilUsuario.pretensao.tipo.pj.valor : ""}
                                    {tipo === "clt" ? this.state.user.perfilUsuario.pretensao.tipo.clt.valor : ""}
                                    {tipo === "others" ? this.state.user.perfilUsuario.pretensao.tipo.others.valor : ""}
                                  </p>
                                </Select>
                              </DropField>
                              <Menu
                                popperModifiers={{
                                  preventOverflow: {
                                    boundariesElement: "viewport",
                                  },
                                }}
                              >
                                {priceRange.map((item: any) => (
                                  <Item
                                    className="d-flex"
                                    key={item.value}
                                    value={item}
                                  >
                                    {item.label}
                                  </Item>
                                ))}
                              </Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </ThemeProvider>
                    ))
                })
              }
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex flex-column h-100">
                <div className="mb-auto"></div>
                <Row className="w-100 d-flex justify-content-end mt-2">
                  <Button
                    className="view-button-save-expectation"
                    onClick={() => {
                      this.updateSalaryExpectation(this.state.user)
                      this.setState({ showPretensao: false })
                    }}
                  >
                    {t("Salvar")}
                  </Button>
                </Row>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      )};
      </div>
    );
  }
}

const mapStateToProps = (store: {
  eventFilterReducer: {
    eventType: any,
    eventDate: any,
    responsibleEvent: any,
    eventComment: any,
    saveEvent: any,
    editEvent: any
  };
}) => ({
  eventType: store.eventFilterReducer.eventType,
  eventDate: store.eventFilterReducer.eventDate,
  responsibleEvent: store.eventFilterReducer.responsibleEvent,
  eventComment: store.eventFilterReducer.eventComment,
  saveEvent: store.eventFilterReducer.saveEvent,
  editEvent: store.eventFilterReducer.editEvent,
});

export default connect(mapStateToProps)(withRouter(ProfessionalView));
